import React from 'react';
import './result.scss';

import { calculatePVU } from '../../util';

function Result({ article, onPMIDChange }) {
  const getOrdinal = (n) => {
    let ord = 'th';

    if (n % 10 === 1 && n % 100 !== 11) {
      ord = 'st';
    }
    else if (n % 10 === 2 && n % 100 !== 12) {
      ord = 'nd';
    }
    else if (n % 10 === 3 && n % 100 !== 13) {
      ord = 'rd';
    }

    return ord;
  }

  let publicationEffortScore = window.localStorage.getItem('pes-' + article.pubmedId)
  if (publicationEffortScore === null) {
    publicationEffortScore = article.publicationEffortScore === 0 ? 1 : article.publicationEffortScore
    window.localStorage.setItem('pes-' + article.pubmedId, publicationEffortScore)
  } else {
    publicationEffortScore = parseInt(publicationEffortScore)
  }

  const pvu = calculatePVU(article.authorPosition, article.totalAuthors, article.highImpact, publicationEffortScore)

  return (
    <li className={'result ' + (article.publicationEffortScore === 0 ? 'flagged-pes' : '')} key={article.title}>
      <h4 className='result__title'>{article.title}</h4>

      <label htmlFor='score-picker'>Publication Effort Score: </label>
      <select className="result__score-picker" name="score-picker"
        onChange={onPMIDChange}>
        <option value='4' selected={publicationEffortScore === 4}>4: Clinical paper/Meta-Analysis/Basic Science Paper</option>
        <option value='3' selected={publicationEffortScore === 3}>3: Case Series/Review/Operative Descriptions</option>
        <option value='2' selected={publicationEffortScore === 2}>2: Case Study/Video</option>
        <option value='1' selected={publicationEffortScore === 1 || publicationEffortScore === 0}>1: Editorial/Historical/Commentary/Letter</option>
      </select>
      <p className="result__author-position">
        Author Position: {article.authorPosition === article.totalAuthors ? "Senior Author" : `${article.authorPosition}${getOrdinal(article.authorPosition)}`}
      </p>
      <p className='result__journal'>
        Journal: {article.journalTitle}
      </p>
      <p className='result__high-impact-pub'>
        High Impact: <span className={`result__high-impact-pub__icon ${article.highImpact && 'high-impact'}`}>{article.highImpact ? '✓' : '✗'}</span>
      </p>
      <p className='result__pvu' id={`result__pvu_${article.pubmedId}`}>
        Publication Value Unit (PVU): {pvu.toFixed(2)}
      </p>
      <a className='result__pubmed-id' href={`https://pubmed.ncbi.nlm.nih.gov/${article.pubmedId}/`}>Pubmed ID: {article.pubmedId}</a>
      <p className='result__summary'><b>Summary: </b>{article.summary || 'No abstract available'}</p>
    </li>
  )

}
export default Result;
