export const calculatePVU = (authorPosition, totalAuthors, highImpact, publicationEffortScore) => {
  let denominator = authorPosition === totalAuthors ? 1 : authorPosition;
  if (highImpact && publicationEffortScore === 4) {
    return 5 / denominator;
  } else {
    return publicationEffortScore / denominator;
  }
}

export const ARTICLES_ENDPOINT = 'https://api.arcscalculator.com';
// export const ARTICLES_ENDPOINT = 'http://127.0.0.1:3001'
