import React, { useEffect, useRef, useState } from 'react';
import * as XLSX from 'xlsx/xlsx.mjs';

import Result from './components/result/default';
import Scores from './components/scores/default';
import SearchInputs from './components/search-inputs/default';
import UnlockModal from './components/unlock-modal/default';

import './index.scss';
import { ARTICLES_ENDPOINT, calculatePVU } from './util';

localStorage.setItem("authors", "");

function App() {
  const [unlocked, setUnlocked] = useState(false);

  const [showUnlockModal, setShowUnlockModal] = useState(false);
  const [showSearch, setShowSearch] = useState();

  const [userInfo, setUserInfo] = useState();
  const [resultsElement, setResultsElement] = useState();
  const [scoresElement, setScoresElement] = useState();
  const [currentAuthor, setCurrentAuthor] = useState();
  const [savedAuthors, setSavedAuthors] = useState();
  const [savedAuthorsElement, setSavedAuthorsElement] = useState();

  const saveAuthorRef = useRef(null);

  useEffect(() => {
    if (!unlocked) {
      setShowUnlockModal(true);
    } else {
      setShowUnlockModal(false);
      setShowSearch(true);
      const alreadySavedScores = JSON.parse(localStorage.getItem("authors") || '[]');

      if (alreadySavedScores.length) {
        setSavedAuthorsElement(
          <ul className='arcs__saved-authors__list' id="authorList">
            {alreadySavedScores.map((author) => {
              return <li className='arcs__saved-authors__author'>{author.author}</li>
            })}
          </ul>
        )
      }
    }
  }, [unlocked]);

  const displayCumulativeScores = (pmids, authorPositionMap, impactMap, totalAuthorsMap) => {
    setScoresElement(
      <Scores
        pmids={pmids}
        authorPositionMap={authorPositionMap}
        impactMap={impactMap}
        totalAuthorsMap={totalAuthorsMap}
      />
    );
  }

  const clearArticles = () => {
    setShowSearch(true);
    setScoresElement(<></>)
    setResultsElement(<></>)
  }

  const onClickSaveAuthor = () => {
    let relevantArticleInfo = [];
    let existingAuthor;
    let newSavedAuthors = [];

    if (currentAuthor?.articles) {
      currentAuthor.articles.forEach((article) => {
        relevantArticleInfo.push({
          pmid: article.pubmedId,
          authorPosition: article.authorPosition,
          score: article.publicationEffortScore,
          highImpact: article.highImpact,
          totalAuthors: article.totalAuthors
        })
      })
    } else {
      alert('There was an error fetching articles, please try again!');
      clearArticles()
      return;
    }

    const alreadySavedScores = JSON.parse(localStorage.getItem("authors") || '[]');

    // pull down existing list of authors from local storage
    // add new author
    // repace local storage with new list
    if (alreadySavedScores) {
      const newEntry = {
        author: currentAuthor.author,
        bundle: {
          scores: relevantArticleInfo
        }
      }

      // check if author has already been saved
      alreadySavedScores.forEach((author, index) => {
        if (author.author === currentAuthor.author) {
          existingAuthor = index;
          return;
        }
      })

      if (existingAuthor >= 0) {
        alert('Author already saved! Data will be overridden')
        newSavedAuthors[existingAuthor] = newEntry;
      } else {
        newSavedAuthors = [...alreadySavedScores, newEntry]
      }
    } else {
      // if no existing list of saved authors, create object as array with one entry
      newSavedAuthors = [{
        author: currentAuthor.author,
        bundle: {
          scores: relevantArticleInfo
        }
      }]
    }

    localStorage.setItem("authors", JSON.stringify(newSavedAuthors));
    setSavedAuthors(newSavedAuthors);

    setSavedAuthorsElement(
      <ul className='arcs__saved-authors__list' id="authorList">
        {newSavedAuthors?.map((author) => {
          return <li className='arcs__saved-authors__author'>{author.author}</li>
        })}
      </ul>
    )
    clearArticles()
  }

  const onSuccessfulSubmit = (author) => {
    if (author?.articles) {
      displayArticles(author.articles);
      setShowSearch(false);
      setCurrentAuthor(author);
    } else {
      setShowSearch(true);
    }

  }

  const gatherExportData = (article) => {
    const highImpact = article.highImpact ? 'yes' : 'no';
    return [article.pmid, article.authorPosition, window.localStorage.getItem('pes-' + article.pmid), highImpact]
  }

  const submitReport = async () => {
    const endpoint = `${ARTICLES_ENDPOINT}/submissions/`

    let submitObject = {
      email: userInfo.email,
      institution: userInfo.institution,
      specialty: userInfo.specialty,
      applicationYear: (userInfo.applicationYear ? userInfo.applicationYear : ''),
      bundles: []
    }

    savedAuthors.forEach((author, index) => {
      submitObject.bundles[index] = {
        scores: author.bundle.scores.map(bundle => Object({
          authorPosition: bundle.authorPosition,
          score: bundle.score,
          highImpact: bundle.highImpact
        }))
      }
    })

    fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // for local testing
        // 'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Headers': '*'
      },
      body: JSON.stringify(submitObject)
    }).catch(error => {
      console.error('Error submitting articles:', error);
    })
  }

  const exportToExcel = async () => {
    const wb = XLSX.utils.book_new()

    if (savedAuthors) {
      const meta_scoring_data = savedAuthors
        .map((author, index) => [author.author, {t: 'n', f: `INDIRECT("'" & A${index + 2} & "'!G${index + 2}")`}, {t: 'n', f: `INDIRECT("'" & A${index + 2} & "'!H${index + 2}")`}, {t: 'n', f: `INDIRECT("'" & A${index + 2} & "'!I${index + 2}")`}])
      const metasheet = XLSX.utils.aoa_to_sheet([['Author Name', 'cPVU', 'ARCS', 'Paper Count'], ...meta_scoring_data])
      try {
        XLSX.utils.book_append_sheet(wb, metasheet, 'Combined Scores')
      } catch (error) {
        console.error('error trying to export! ', error)
      }

      savedAuthors
        .forEach((item, author_index) => {
          let exportData = item.bundle.scores
          .map(gatherExportData)
          .map((element,index) => [...element, {t: 'n', f:`ROUND((C${index + 2}+(IF(EXACT(D${index + 2},"yes"),1,0)))/B${index + 2}, 2)`}])
          .map((element,index) => {
            if (index === 0) {
              return [...element, '', {t: 'n', f: 'SUM(E2:E1000)'}, {t: 'n', f: 'SUMIF(E2:E1000, ">=1")'}, {t: 'n', f: 'COUNTA(A2:A1000)'}]
            }
            return element
          })

          let ws = XLSX.utils.aoa_to_sheet([['PMID', 'Author Position', 'Effort Score', 'High Impact?', 'PVU', '', 'cPVU', 'ARCS', 'Count',], ...exportData])
          try {
            XLSX.utils.book_append_sheet(wb, ws, `${item.author}`)
          } catch (error) {
            console.error('error trying to export! ', error)
          }

        })

      XLSX.writeFile(wb, 'ArcsScores.xlsx')
    }

    submitReport();
  }

  function displayArticles(articles) {
    let articlesList;

    if (!articles) {
      return;
    }

    const pmids = articles.map(article => article.pubmedId)

    const pmidToAuthorPositionMap = new Map();
    const pmidToTotalAuthorsMap = new Map();
    const pmidToImpactMap = new Map();

    articles.forEach(article => {
      const pmid = article.pubmedId;

      pmidToAuthorPositionMap.set(pmid, article.authorPosition)
      pmidToTotalAuthorsMap.set(pmid, article.totalAuthors)
      pmidToImpactMap.set(pmid, article.highImpact)
    })

    if (articles.length === 0) {
      articlesList =
        <div className='arcs__results'>
          <h3 className="arcs__results__header">'No articles found for the values provided. Please double check author name and IDs.'</h3>
        </div>
    } else {
      articlesList =
        <div className="arcs__results">
          <h3 className="arcs__results__header">Results: </h3>
          <h4 className="arcs__results__screening_message">Publications highlighted in red require additional screening.</h4>
          <div className="arcs__results">
            <ul className="arcs__results__articles-list" id="articlesList">
              {articles.map((article) => {
                const onSelectChange = (event) => {
                  const newPvu = calculatePVU(article.authorPosition, article.totalAuthors, article.highImpact, event.target.value);
                  window.localStorage.setItem('pes-' + article.pubmedId, event.target.value);
                  document.getElementById('result__pvu_' + article.pubmedId).textContent = `Publication Value Unit (PVU): ${newPvu.toFixed(2)}`;
                  displayCumulativeScores(pmids, pmidToAuthorPositionMap, pmidToImpactMap, pmidToTotalAuthorsMap)
                }

                return <Result article={article} onPMIDChange={onSelectChange} />
              })}
            </ul>
          </div>
        </div>
    }
    setResultsElement(articlesList)
    displayCumulativeScores(pmids, pmidToAuthorPositionMap, pmidToImpactMap, pmidToTotalAuthorsMap)
  }

  return (
    <div className='arcs'>
      <UnlockModal showModal={showUnlockModal} setUnlock={setUnlocked} setUserInfo={setUserInfo} />

      <h3 className="arcs__warning">
        Please verify each score and manually and adjust the score if not felt to be accurate. <br /> Please report any issues to <a href="mailto:arcscalculator@gmail.com"> arcscalculator@gmail.com</a>
      </h3>
      <header className='arcs__header'>
        <div className='arcs__header__container'>
          <div className='arcs__header__text'>
            <h1 className='arcs__header__title'>Calculate ARCS Score for an Author</h1>
            <h5 className='arcs__header__subtitle'>This tool is for research purposes only. Data provided by <a href='https://pubmed.ncbi.nlm.nih.gov'>Pubmed</a>.</h5>
          </div>

          <div className='arcs__header__buttons'>
            {!showSearch && unlocked && (
              <button ref={saveAuthorRef} className="arcs__header__new-author" id="saveAuthorButton" type='button' onClick={onClickSaveAuthor}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
                  <path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" />
                </svg>
                Save and add new author
              </button>
            )}

            {!showSearch && unlocked && (
              <button className="arcs__header__clear-author" id="clearAuthorButton" type='button' onClick={clearArticles}>
                Clear current author
              </button>
            )}
            {(savedAuthors && savedAuthors.length > 0) &&
              <div className="arcs__export-buttons">
                <button className="arcs__export-buttons__export" id="exportAll" onClick={exportToExcel} >
                  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd">
                    <path d="M16 2v7h-2v-5h-12v16h12v-5h2v7h-16v-20h16zm2 9v-4l6 5-6 5v-4h-10v-2h10z" />
                  </svg>
                  Export Authors
                </button>
              </div>
            }
          </div>
        </div>
      </header>

      {(unlocked && showSearch) &&
        <SearchInputs onSuccessfulSubmit={onSuccessfulSubmit} setShowSearch={setShowSearch} />
      }


      <div className="arcs__loading hidden" id="loadingMessage">
        <div className="lds-ring">
          <div></div><div></div><div></div><div></div>
        </div>
        <span>Loading...</span>
      </div>

      <div className="arcs__filtered hidden" id="filteredPmidsMessageContainer">
        <p className="arcs__filtered__message">The following pubmed IDs were filtered because they were invalid or the author name was not present: </p>
        <p className="arcs__filtered__ids" id="filteredPmids"></p>
      </div>

      <div className='arcs__content'>
        <div className='arcs__content__left'>
          {scoresElement}
          {resultsElement}
        </div>

        <div className='arcs__content__right'>
          <div className='arcs__saved-authors'>
            <h3 className='arcs__saved-authors__title'>Saved Applicants:</h3>
            {savedAuthorsElement}
          </div>
        </div>
      </div>



      {(showUnlockModal && showSearch) && <div className='arcs__overlay'></div>}
    </div>
  );
}

export default App;
