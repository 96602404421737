import React from 'react';

import { ARTICLES_ENDPOINT } from '../../util';
import './search-inputs.scss';

function SearchInputs({ onSuccessfulSubmit, setShowSearch }) {
  const showLoading = () => {
    document.getElementById('loadingMessage').classList.remove('hidden')
  }

  const hideLoading = () => {
    document.getElementById('loadingMessage').classList.add('hidden')
  }

  const stuffPEStoLocalStorage = (articles) => {
    articles.forEach(article => {
      if (window.localStorage.getItem('pes-' + article.pubmedId) === null) {
        let publicationEffortScore = article.publicationEffortScore === 0 ? 1 : article.publicationEffortScore
        window.localStorage.setItem('pes-' + article.pubmedId, publicationEffortScore)
      }
    })
  }

  const searchArticles = async () => {
    const authorNameElement = document.getElementById('authorName')
    const authorInitElement = document.getElementById('authorInitial')
    const pubmedIdElement = document.getElementById('pmids')

    const authorLastName = authorNameElement.value.trim()
    const authorInitial = authorInitElement.value.trim()
    const pubmedIdText = pubmedIdElement.value.trim()

    let savedAuthorObject = {};

    if (!authorLastName || !(pubmedIdText)) {
      alert('Please provide an author name and PubMed IDs.');
      return;
    }

    setShowSearch(false);
    showLoading();

    const pmids = [...new Set(pubmedIdText.split(',').map(id => id.trim()).filter(id => id !== ''))]
    savedAuthorObject['author'] = authorLastName;

    try {
      const endpoint = `${ARTICLES_ENDPOINT}/articles/`
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ authorInitial, authorLastName, pmids })
      });

      switch (response.status) {
        case 503:
          alert('Pubmed servers are temporarily unavailable. Please try again in a few minutes. If this issue perisists, please email us.')
          return
        case 500:
          alert('We encountered an unexpected error while processing your request. Please try again in a few minutes. If this issue perisists, please email us.')
          return
        case 400:
          alert('We were unable to process your request. Please check the Author name and Pubmed IDs and make sure all are valid.');
          return
      }

      const data = await response.json()
      const articles = data.summaries
      stuffPEStoLocalStorage(articles)
      savedAuthorObject['articles'] = articles;
      const exportButtonDiv = document.querySelectorAll('.exportButtons')
      exportButtonDiv.forEach(el => el.classList.remove('hidden'))
    } catch (error) {
      console.error('Error fetching articles:', error);
      alert('We encountered an unexpected error while processing your request. Please try again in a few minutes. If this issue perisists, please email us.')
    } finally {
      hideLoading();
      onSuccessfulSubmit(savedAuthorObject);
    }
  }

  /* TEST VALUES:
    Eshraghi
    31835242,36597932,27436207,34097740,27939797,33989398,27216924
  
    Kraus
    37473867,36103986,33370811,32875851,26123506,25434710,21153668,20651622,20628820,19392590,15739545
  */

  return (
    <div className='search-inputs'>
      <div className="search-inputs__inputs">
        <div className="search-inputs__inputs__first_initial">
          <label htmlFor="authorInitial">Enter First Initial (optional):</label>
          <input type="text" id="authorInitial"></input>
        </div>
        <div className="search-inputs__inputs__last-name">
          <label htmlFor="authorName">Enter Author's Last Name:</label>
          <input type="text" id="authorName"></input>
          {/* <input type="text" id="authorName" value='Eshraghi'></input> */}
        </div>
        <div className="search-inputs__inputs__ids">
          <label htmlFor="pmids">Enter PubMed IDs separated by commas:</label>
          <textarea type="text" id="pmids"></textarea>
          {/* <textarea type="text" id="pmids" value='31835242,36597932,27436207,34097740,27939797,33989398,27216924'></textarea> */}
        </div>
      </div>
      <div className="search-inputs__save-submit">
        <button className="search-inputs__save-submit__submit" id="submitButton" onClick={searchArticles} type='submit'>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill='white'>
            <path d="M 21 3 C 11.601563 3 4 10.601563 4 20 C 4 29.398438 11.601563 37 21 37 C 24.355469 37 27.460938 36.015625 30.09375 34.34375 L 42.375 46.625 L 46.625 42.375 L 34.5 30.28125 C 36.679688 27.421875 38 23.878906 38 20 C 38 10.601563 30.398438 3 21 3 Z M 21 7 C 28.199219 7 34 12.800781 34 20 C 34 27.199219 28.199219 33 21 33 C 13.800781 33 8 27.199219 8 20 C 8 12.800781 13.800781 7 21 7 Z"></path>
          </svg>
          Search
        </button>
      </div>
    </div>
  )

}
export default SearchInputs;
